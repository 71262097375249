<template>
    <div class="c_entry_list_wrap">
        <div class="competition_entry_list_wrap">

            <!--    공모전 응모작 리스트 레이아웃-->
            <!--    <button >상세 페이지</button>-->
            <div class="item_list_entry_wrap">
                <ul class="entry_list_wrap">
                    <li class="list_item" v-for="entry in competitionData" :key="`${entry.ci_idx}`"
                    @click.stop="moveDetail(entry.ci_idx)">
                        <div class="thumbnail" :style="`background-image : url(${setFitWidthImg(entry.Item.ItemFile.Item, 276)})`"></div>
                        <div class="card_detail">
                            <div class="card_title">{{entry.Item.i_name}}</div>
                            <div class="card_bottom">
                                <div class="profile_group">
                                    <div class="card_profile" :style="`background-image : url(${returnImage(entry.Profile.Profile,30,30)})`"></div>
                                    <div class="user_name">{{entry.Item.originator_nick}}</div>
                                </div>
                                <div class="icon_group">
                                    <div class="icon_wrap pr-10">
                                        <img class="icon vote" src="@/assets/image/main/vote.png" alt="" @click="voteItem(entry.ci_idx,$event)">
                                        <div class="icon_count">{{returnCommas(entry.ci_vote_cnt)}}({{returnPercentage(entry.ci_vote_cnt,entry.totalVote)}}%)</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </li>

                </ul>

            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CompetitionListEntryLayout",
    mixins: [imageOption, alertMixins, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {
        }
    },
    props: {
        competitionData: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            idx: 1,
            entryItemLists: 12,

            contestData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getContest();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        getContest() {
            if(util.isEmpty(this.$route.params.cid)) {
                return false;
            }
            let params = {
                //id: this.$route.params.cid,
                cuid: this.$route.params.cid,
            }
            try {
                this.$api.$competition.getCompetition(params).then(res => res.Data.Result).then(res => {
                    if(util.isEmpty(res.List)) {
                        return false;
                    }
                    this.contestData = res.List[0];
                })
            }catch (e) {
                console.log(e);
            }
        },
        moveDetail(data) {
            let path = this.$route.path
            this.$router.push(`${path}/${data}`);
        },
        returnCommas(x=0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnPercentage(v,t){
            if(t !== 0){
                return parseFloat((v / t * 100).toFixed(2))
            }else {
                return 0
            }
        },
        returnImage(image,width=32,height=32, f='png') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, this.returnExtension(image[0].org_url))}`
            }
            return ''
        },
        returnOrgImage(image) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}`
            }
            return "";
        },
        voteItem(ci_idx, e){
            e.stopPropagation()
            if(util.isEmpty(this.contestData) || this.contestData.c_status != 1) {
                this.errorAlert(this.$t('vote_err_date'))
                return false;
            }
            e.stopPropagation()
            EventBus.$emit('voteItem',ci_idx)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
